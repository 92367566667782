export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  DateTime: string;
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  Dimension: any;
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  HexColor: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  Quality: any;
};

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type Article = Entry & {
  __typename?: 'Article';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ArticleLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  featured?: Maybe<Scalars['Boolean']>;
  cover?: Maybe<Asset>;
  content?: Maybe<ArticleContent>;
  relatedArticlesCollection?: Maybe<ArticleRelatedArticlesCollection>;
  seoDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleSubtitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleSlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleCategoryArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleFeaturedArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleCoverArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleContentArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleRelatedArticlesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleSeoDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/article) */
export type ArticleMetaTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type ArticleCollection = {
  __typename?: 'ArticleCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Article>>;
};

export type ArticleContent = {
  __typename?: 'ArticleContent';
  json: Scalars['JSON'];
  links: ArticleContentLinks;
};

export type ArticleContentAssets = {
  __typename?: 'ArticleContentAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type ArticleContentEntries = {
  __typename?: 'ArticleContentEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type ArticleContentLinks = {
  __typename?: 'ArticleContentLinks';
  entries: ArticleContentEntries;
  assets: ArticleContentAssets;
};

export type ArticleFilter = {
  category?: Maybe<CfCategoryNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  subtitle_exists?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_not?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_contains?: Maybe<Scalars['String']>;
  subtitle_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  category_exists?: Maybe<Scalars['Boolean']>;
  featured_exists?: Maybe<Scalars['Boolean']>;
  featured?: Maybe<Scalars['Boolean']>;
  featured_not?: Maybe<Scalars['Boolean']>;
  cover_exists?: Maybe<Scalars['Boolean']>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  relatedArticlesCollection_exists?: Maybe<Scalars['Boolean']>;
  seoDescription_exists?: Maybe<Scalars['Boolean']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescription_not?: Maybe<Scalars['String']>;
  seoDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  seoDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  seoDescription_contains?: Maybe<Scalars['String']>;
  seoDescription_not_contains?: Maybe<Scalars['String']>;
  metaTitle_exists?: Maybe<Scalars['Boolean']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaTitle_not?: Maybe<Scalars['String']>;
  metaTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  metaTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  metaTitle_contains?: Maybe<Scalars['String']>;
  metaTitle_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<ArticleFilter>>>;
  AND?: Maybe<Array<Maybe<ArticleFilter>>>;
};

export type ArticleLinkingCollections = {
  __typename?: 'ArticleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  articleCollection?: Maybe<ArticleCollection>;
  landingPageCollection?: Maybe<LandingPageCollection>;
};


export type ArticleLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type ArticleLinkingCollectionsArticleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type ArticleLinkingCollectionsLandingPageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum ArticleOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  FeaturedAsc = 'featured_ASC',
  FeaturedDesc = 'featured_DESC',
  SeoDescriptionAsc = 'seoDescription_ASC',
  SeoDescriptionDesc = 'seoDescription_DESC',
  MetaTitleAsc = 'metaTitle_ASC',
  MetaTitleDesc = 'metaTitle_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ArticleRelatedArticlesCollection = {
  __typename?: 'ArticleRelatedArticlesCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Article>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  transform?: Maybe<ImageTransformOptions>;
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Asset>>;
};

export type AssetFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  description_exists?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  url_exists?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  size_exists?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Int']>;
  size_not?: Maybe<Scalars['Int']>;
  size_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_gt?: Maybe<Scalars['Int']>;
  size_gte?: Maybe<Scalars['Int']>;
  size_lt?: Maybe<Scalars['Int']>;
  size_lte?: Maybe<Scalars['Int']>;
  contentType_exists?: Maybe<Scalars['Boolean']>;
  contentType?: Maybe<Scalars['String']>;
  contentType_not?: Maybe<Scalars['String']>;
  contentType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_contains?: Maybe<Scalars['String']>;
  contentType_not_contains?: Maybe<Scalars['String']>;
  fileName_exists?: Maybe<Scalars['Boolean']>;
  fileName?: Maybe<Scalars['String']>;
  fileName_not?: Maybe<Scalars['String']>;
  fileName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_contains?: Maybe<Scalars['String']>;
  fileName_not_contains?: Maybe<Scalars['String']>;
  width_exists?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  width_not?: Maybe<Scalars['Int']>;
  width_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_gt?: Maybe<Scalars['Int']>;
  width_gte?: Maybe<Scalars['Int']>;
  width_lt?: Maybe<Scalars['Int']>;
  width_lte?: Maybe<Scalars['Int']>;
  height_exists?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  height_not?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_gt?: Maybe<Scalars['Int']>;
  height_gte?: Maybe<Scalars['Int']>;
  height_lt?: Maybe<Scalars['Int']>;
  height_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<AssetFilter>>>;
  AND?: Maybe<Array<Maybe<AssetFilter>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  branchCollection?: Maybe<BranchCollection>;
  articleCollection?: Maybe<ArticleCollection>;
  hiringCollection?: Maybe<HiringCollection>;
  branchMemberCollection?: Maybe<BranchMemberCollection>;
  imageGalleryCollection?: Maybe<ImageGalleryCollection>;
};


export type AssetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsBranchCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsArticleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsHiringCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsBranchMemberCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsImageGalleryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum AssetOrder {
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type Branch = Entry & {
  __typename?: 'Branch';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<BranchLinkingCollections>;
  branchId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  imagesCollection?: Maybe<AssetCollection>;
  membersCollection?: Maybe<BranchMembersCollection>;
  reviewsCollection?: Maybe<BranchReviewsCollection>;
  slug?: Maybe<Scalars['String']>;
  sellingAbout?: Maybe<Scalars['String']>;
  calendlyLink?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchBranchIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchAboutArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchImagesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchMembersCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchReviewsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchSlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchSellingAboutArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branch) */
export type BranchCalendlyLinkArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type BranchCollection = {
  __typename?: 'BranchCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Branch>>;
};

export type BranchFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  branchId_exists?: Maybe<Scalars['Boolean']>;
  branchId?: Maybe<Scalars['String']>;
  branchId_not?: Maybe<Scalars['String']>;
  branchId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  branchId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  branchId_contains?: Maybe<Scalars['String']>;
  branchId_not_contains?: Maybe<Scalars['String']>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  about_exists?: Maybe<Scalars['Boolean']>;
  about?: Maybe<Scalars['String']>;
  about_not?: Maybe<Scalars['String']>;
  about_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  about_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  about_contains?: Maybe<Scalars['String']>;
  about_not_contains?: Maybe<Scalars['String']>;
  imagesCollection_exists?: Maybe<Scalars['Boolean']>;
  membersCollection_exists?: Maybe<Scalars['Boolean']>;
  reviewsCollection_exists?: Maybe<Scalars['Boolean']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  sellingAbout_exists?: Maybe<Scalars['Boolean']>;
  sellingAbout?: Maybe<Scalars['String']>;
  sellingAbout_not?: Maybe<Scalars['String']>;
  sellingAbout_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sellingAbout_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sellingAbout_contains?: Maybe<Scalars['String']>;
  sellingAbout_not_contains?: Maybe<Scalars['String']>;
  calendlyLink_exists?: Maybe<Scalars['Boolean']>;
  calendlyLink?: Maybe<Scalars['String']>;
  calendlyLink_not?: Maybe<Scalars['String']>;
  calendlyLink_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  calendlyLink_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  calendlyLink_contains?: Maybe<Scalars['String']>;
  calendlyLink_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<BranchFilter>>>;
  AND?: Maybe<Array<Maybe<BranchFilter>>>;
};

export type BranchLinkingCollections = {
  __typename?: 'BranchLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type BranchLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branchMember) */
export type BranchMember = Entry & {
  __typename?: 'BranchMember';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<BranchMemberLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  photo?: Maybe<Asset>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branchMember) */
export type BranchMemberLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branchMember) */
export type BranchMemberNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branchMember) */
export type BranchMemberPositionArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/branchMember) */
export type BranchMemberPhotoArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type BranchMemberCollection = {
  __typename?: 'BranchMemberCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<BranchMember>>;
};

export type BranchMemberFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  position_exists?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['String']>;
  position_not?: Maybe<Scalars['String']>;
  position_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  position_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  position_contains?: Maybe<Scalars['String']>;
  position_not_contains?: Maybe<Scalars['String']>;
  photo_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<BranchMemberFilter>>>;
  AND?: Maybe<Array<Maybe<BranchMemberFilter>>>;
};

export type BranchMemberLinkingCollections = {
  __typename?: 'BranchMemberLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  branchCollection?: Maybe<BranchCollection>;
};


export type BranchMemberLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type BranchMemberLinkingCollectionsBranchCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum BranchMemberOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type BranchMembersCollection = {
  __typename?: 'BranchMembersCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<BranchMember>>;
};

export enum BranchOrder {
  BranchIdAsc = 'branchId_ASC',
  BranchIdDesc = 'branchId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type BranchReviewsCollection = {
  __typename?: 'BranchReviewsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<UserReview>>;
};

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/category) */
export type Category = Entry & {
  __typename?: 'Category';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<CategoryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/category) */
export type CategoryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/category) */
export type CategoryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/category) */
export type CategoryTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type CategoryCollection = {
  __typename?: 'CategoryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Category>>;
};

export type CategoryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  type_exists?: Maybe<Scalars['Boolean']>;
  type_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>;
  OR?: Maybe<Array<Maybe<CategoryFilter>>>;
  AND?: Maybe<Array<Maybe<CategoryFilter>>>;
};

export type CategoryLinkingCollections = {
  __typename?: 'CategoryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  articleCollection?: Maybe<ArticleCollection>;
  updateCollection?: Maybe<UpdateCollection>;
};


export type CategoryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type CategoryLinkingCollectionsArticleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type CategoryLinkingCollectionsUpdateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum CategoryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags_exists?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<ContentfulMetadataTagsFilter>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};



export type Entry = {
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};

export type EntryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  OR?: Maybe<Array<Maybe<EntryFilter>>>;
  AND?: Maybe<Array<Maybe<EntryFilter>>>;
};

export enum EntryOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/example) */
export type Example = Entry & {
  __typename?: 'Example';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ExampleLinkingCollections>;
  makeId?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/example) */
export type ExampleLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/example) */
export type ExampleMakeIdArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type ExampleCollection = {
  __typename?: 'ExampleCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Example>>;
};

export type ExampleFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  makeId_exists?: Maybe<Scalars['Boolean']>;
  makeId?: Maybe<Scalars['String']>;
  makeId_not?: Maybe<Scalars['String']>;
  makeId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  makeId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  makeId_contains?: Maybe<Scalars['String']>;
  makeId_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<ExampleFilter>>>;
  AND?: Maybe<Array<Maybe<ExampleFilter>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/exampleGlobal) */
export type ExampleGlobal = Entry & {
  __typename?: 'ExampleGlobal';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ExampleGlobalLinkingCollections>;
  localizedCollection?: Maybe<ExampleGlobalLocalizedCollection>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/exampleGlobal) */
export type ExampleGlobalLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/exampleGlobal) */
export type ExampleGlobalLocalizedCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type ExampleGlobalCollection = {
  __typename?: 'ExampleGlobalCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ExampleGlobal>>;
};

export type ExampleGlobalFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  localizedCollection_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<ExampleGlobalFilter>>>;
  AND?: Maybe<Array<Maybe<ExampleGlobalFilter>>>;
};

export type ExampleGlobalLinkingCollections = {
  __typename?: 'ExampleGlobalLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ExampleGlobalLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type ExampleGlobalLocalizedCollection = {
  __typename?: 'ExampleGlobalLocalizedCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ExampleLocal>>;
};

export enum ExampleGlobalOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ExampleLinkingCollections = {
  __typename?: 'ExampleLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ExampleLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/exampleLocal) */
export type ExampleLocal = Entry & {
  __typename?: 'ExampleLocal';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ExampleLocalLinkingCollections>;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/exampleLocal) */
export type ExampleLocalLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/exampleLocal) */
export type ExampleLocalTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type ExampleLocalCollection = {
  __typename?: 'ExampleLocalCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ExampleLocal>>;
};

export type ExampleLocalFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<ExampleLocalFilter>>>;
  AND?: Maybe<Array<Maybe<ExampleLocalFilter>>>;
};

export type ExampleLocalLinkingCollections = {
  __typename?: 'ExampleLocalLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  exampleGlobalCollection?: Maybe<ExampleGlobalCollection>;
};


export type ExampleLocalLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type ExampleLocalLinkingCollectionsExampleGlobalCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum ExampleLocalOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ExampleOrder {
  MakeIdAsc = 'makeId_ASC',
  MakeIdDesc = 'makeId_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqCategory) */
export type FaqCategory = Entry & {
  __typename?: 'FaqCategory';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<FaqCategoryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqCategory) */
export type FaqCategoryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqCategory) */
export type FaqCategoryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqCategory) */
export type FaqCategoryTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type FaqCategoryCollection = {
  __typename?: 'FaqCategoryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<FaqCategory>>;
};

export type FaqCategoryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  type_exists?: Maybe<Scalars['Boolean']>;
  type_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>;
  OR?: Maybe<Array<Maybe<FaqCategoryFilter>>>;
  AND?: Maybe<Array<Maybe<FaqCategoryFilter>>>;
};

export type FaqCategoryLinkingCollections = {
  __typename?: 'FaqCategoryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  faqQuestionCollection?: Maybe<FaqQuestionCollection>;
};


export type FaqCategoryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type FaqCategoryLinkingCollectionsFaqQuestionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum FaqCategoryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqQuestion) */
export type FaqQuestion = Entry & {
  __typename?: 'FaqQuestion';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<FaqQuestionLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  faqCategory?: Maybe<FaqCategory>;
  content?: Maybe<FaqQuestionContent>;
  order?: Maybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqQuestion) */
export type FaqQuestionLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqQuestion) */
export type FaqQuestionTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqQuestion) */
export type FaqQuestionFaqCategoryArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqQuestion) */
export type FaqQuestionContentArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/faqQuestion) */
export type FaqQuestionOrderArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type FaqQuestionCollection = {
  __typename?: 'FaqQuestionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<FaqQuestion>>;
};

export type FaqQuestionContent = {
  __typename?: 'FaqQuestionContent';
  json: Scalars['JSON'];
  links: FaqQuestionContentLinks;
};

export type FaqQuestionContentAssets = {
  __typename?: 'FaqQuestionContentAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type FaqQuestionContentEntries = {
  __typename?: 'FaqQuestionContentEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type FaqQuestionContentLinks = {
  __typename?: 'FaqQuestionContentLinks';
  entries: FaqQuestionContentEntries;
  assets: FaqQuestionContentAssets;
};

export type FaqQuestionFilter = {
  faqCategory?: Maybe<CfFaqCategoryNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  faqCategory_exists?: Maybe<Scalars['Boolean']>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  order_exists?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<FaqQuestionFilter>>>;
  AND?: Maybe<Array<Maybe<FaqQuestionFilter>>>;
};

export type FaqQuestionLinkingCollections = {
  __typename?: 'FaqQuestionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type FaqQuestionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum FaqQuestionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/hiring) */
export type Hiring = Entry & {
  __typename?: 'Hiring';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<HiringLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<HiringContent>;
  slug?: Maybe<Scalars['String']>;
  role?: Maybe<RoleSection>;
  localizationvisibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationsCollection?: Maybe<HiringLocationsCollection>;
  video?: Maybe<Asset>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/hiring) */
export type HiringLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/hiring) */
export type HiringTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/hiring) */
export type HiringContentArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/hiring) */
export type HiringSlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/hiring) */
export type HiringRoleArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/hiring) */
export type HiringLocalizationvisibilityArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/hiring) */
export type HiringLocationsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/hiring) */
export type HiringVideoArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type HiringCollection = {
  __typename?: 'HiringCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Hiring>>;
};

export type HiringContent = {
  __typename?: 'HiringContent';
  json: Scalars['JSON'];
  links: HiringContentLinks;
};

export type HiringContentAssets = {
  __typename?: 'HiringContentAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type HiringContentEntries = {
  __typename?: 'HiringContentEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type HiringContentLinks = {
  __typename?: 'HiringContentLinks';
  entries: HiringContentEntries;
  assets: HiringContentAssets;
};

export type HiringFilter = {
  role?: Maybe<CfRoleSectionNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  role_exists?: Maybe<Scalars['Boolean']>;
  localizationvisibility_exists?: Maybe<Scalars['Boolean']>;
  localizationvisibility_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>;
  localizationvisibility_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>;
  localizationvisibility_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationsCollection_exists?: Maybe<Scalars['Boolean']>;
  video_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<HiringFilter>>>;
  AND?: Maybe<Array<Maybe<HiringFilter>>>;
};

export type HiringLinkingCollections = {
  __typename?: 'HiringLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type HiringLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type HiringLocationsCollection = {
  __typename?: 'HiringLocationsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<RoleLocation>>;
};

export enum HiringOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageFormat {
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
  Avif = 'AVIF'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/imageGallery) */
export type ImageGallery = Entry & {
  __typename?: 'ImageGallery';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ImageGalleryLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  imagesCollection?: Maybe<AssetCollection>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/imageGallery) */
export type ImageGalleryLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/imageGallery) */
export type ImageGalleryTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/imageGallery) */
export type ImageGalleryImagesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type ImageGalleryCollection = {
  __typename?: 'ImageGalleryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ImageGallery>>;
};

export type ImageGalleryFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  imagesCollection_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<ImageGalleryFilter>>>;
  AND?: Maybe<Array<Maybe<ImageGalleryFilter>>>;
};

export type ImageGalleryLinkingCollections = {
  __typename?: 'ImageGalleryLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ImageGalleryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum ImageGalleryOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageResizeFocus {
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES'
}

export enum ImageResizeStrategy {
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB'
}

export type ImageTransformOptions = {
  /** Desired width in pixels. Defaults to the original image width. */
  width?: Maybe<Scalars['Dimension']>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: Maybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: Maybe<Scalars['Quality']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: Maybe<Scalars['Int']>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: Maybe<ImageResizeStrategy>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: Maybe<ImageResizeFocus>;
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: Maybe<Scalars['HexColor']>;
  /** Desired image format. Defaults to the original image format. */
  format?: Maybe<ImageFormat>;
};

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type Impressum = Entry & {
  __typename?: 'Impressum';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<ImpressumLinkingCollections>;
  heading?: Maybe<Scalars['String']>;
  hotlineTitle?: Maybe<Scalars['String']>;
  hotline?: Maybe<Scalars['String']>;
  helpAndContactTitle?: Maybe<Scalars['String']>;
  helpAndContact?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  faxTitle?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  content?: Maybe<ImpressumContent>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumHeadingArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumHotlineTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumHotlineArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumHelpAndContactTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumHelpAndContactArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumSubheadingArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumAddressArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumFaxTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumFaxArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumEmailArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/impressum) */
export type ImpressumContentArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type ImpressumCollection = {
  __typename?: 'ImpressumCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Impressum>>;
};

export type ImpressumContent = {
  __typename?: 'ImpressumContent';
  json: Scalars['JSON'];
  links: ImpressumContentLinks;
};

export type ImpressumContentAssets = {
  __typename?: 'ImpressumContentAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type ImpressumContentEntries = {
  __typename?: 'ImpressumContentEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type ImpressumContentLinks = {
  __typename?: 'ImpressumContentLinks';
  entries: ImpressumContentEntries;
  assets: ImpressumContentAssets;
};

export type ImpressumFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  heading_exists?: Maybe<Scalars['Boolean']>;
  heading?: Maybe<Scalars['String']>;
  heading_not?: Maybe<Scalars['String']>;
  heading_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  heading_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  heading_contains?: Maybe<Scalars['String']>;
  heading_not_contains?: Maybe<Scalars['String']>;
  hotline_title_exists?: Maybe<Scalars['Boolean']>;
  hotline_title?: Maybe<Scalars['String']>;
  hotline_title_not?: Maybe<Scalars['String']>;
  hotline_title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hotline_title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hotline_title_contains?: Maybe<Scalars['String']>;
  hotline_title_not_contains?: Maybe<Scalars['String']>;
  hotline_exists?: Maybe<Scalars['Boolean']>;
  hotline?: Maybe<Scalars['String']>;
  hotline_not?: Maybe<Scalars['String']>;
  hotline_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hotline_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hotline_contains?: Maybe<Scalars['String']>;
  hotline_not_contains?: Maybe<Scalars['String']>;
  help_and_contact_title_exists?: Maybe<Scalars['Boolean']>;
  help_and_contact_title?: Maybe<Scalars['String']>;
  help_and_contact_title_not?: Maybe<Scalars['String']>;
  help_and_contact_title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  help_and_contact_title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  help_and_contact_title_contains?: Maybe<Scalars['String']>;
  help_and_contact_title_not_contains?: Maybe<Scalars['String']>;
  help_and_contact_exists?: Maybe<Scalars['Boolean']>;
  help_and_contact?: Maybe<Scalars['String']>;
  help_and_contact_not?: Maybe<Scalars['String']>;
  help_and_contact_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  help_and_contact_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  help_and_contact_contains?: Maybe<Scalars['String']>;
  help_and_contact_not_contains?: Maybe<Scalars['String']>;
  subheading_exists?: Maybe<Scalars['Boolean']>;
  subheading?: Maybe<Scalars['String']>;
  subheading_not?: Maybe<Scalars['String']>;
  subheading_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subheading_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subheading_contains?: Maybe<Scalars['String']>;
  subheading_not_contains?: Maybe<Scalars['String']>;
  address_exists?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  fax_title_exists?: Maybe<Scalars['Boolean']>;
  fax_title?: Maybe<Scalars['String']>;
  fax_title_not?: Maybe<Scalars['String']>;
  fax_title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fax_title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fax_title_contains?: Maybe<Scalars['String']>;
  fax_title_not_contains?: Maybe<Scalars['String']>;
  fax_exists?: Maybe<Scalars['Boolean']>;
  fax?: Maybe<Scalars['String']>;
  fax_not?: Maybe<Scalars['String']>;
  fax_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fax_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fax_contains?: Maybe<Scalars['String']>;
  fax_not_contains?: Maybe<Scalars['String']>;
  email_exists?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<ImpressumFilter>>>;
  AND?: Maybe<Array<Maybe<ImpressumFilter>>>;
};

export type ImpressumLinkingCollections = {
  __typename?: 'ImpressumLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type ImpressumLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum ImpressumOrder {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  HotlineTitleAsc = 'hotline_title_ASC',
  HotlineTitleDesc = 'hotline_title_DESC',
  HotlineAsc = 'hotline_ASC',
  HotlineDesc = 'hotline_DESC',
  HelpAndContactTitleAsc = 'help_and_contact_title_ASC',
  HelpAndContactTitleDesc = 'help_and_contact_title_DESC',
  HelpAndContactAsc = 'help_and_contact_ASC',
  HelpAndContactDesc = 'help_and_contact_DESC',
  SubheadingAsc = 'subheading_ASC',
  SubheadingDesc = 'subheading_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  FaxTitleAsc = 'fax_title_ASC',
  FaxTitleDesc = 'fax_title_DESC',
  FaxAsc = 'fax_ASC',
  FaxDesc = 'fax_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/landingPage) */
export type LandingPage = Entry & {
  __typename?: 'LandingPage';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<LandingPageLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  featuredArticle?: Maybe<Article>;
  articlesCollection?: Maybe<LandingPageArticlesCollection>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/landingPage) */
export type LandingPageLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/landingPage) */
export type LandingPageTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/landingPage) */
export type LandingPageTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/landingPage) */
export type LandingPageFeaturedArticleArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/landingPage) */
export type LandingPageArticlesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export type LandingPageArticlesCollection = {
  __typename?: 'LandingPageArticlesCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Article>>;
};

export type LandingPageCollection = {
  __typename?: 'LandingPageCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<LandingPage>>;
};

export type LandingPageFilter = {
  featuredArticle?: Maybe<CfArticleNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  type_exists?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  featuredArticle_exists?: Maybe<Scalars['Boolean']>;
  articlesCollection_exists?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<LandingPageFilter>>>;
  AND?: Maybe<Array<Maybe<LandingPageFilter>>>;
};

export type LandingPageLinkingCollections = {
  __typename?: 'LandingPageLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type LandingPageLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum LandingPageOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/legal_document) */
export type LegalDocument = Entry & {
  __typename?: 'LegalDocument';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<LegalDocumentLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/legal_document) */
export type LegalDocumentLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/legal_document) */
export type LegalDocumentTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/legal_document) */
export type LegalDocumentContentArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/legal_document) */
export type LegalDocumentHintArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/legal_document) */
export type LegalDocumentTypeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/legal_document) */
export type LegalDocumentOrderArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type LegalDocumentCollection = {
  __typename?: 'LegalDocumentCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<LegalDocument>>;
};

export type LegalDocumentFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  hint_exists?: Maybe<Scalars['Boolean']>;
  hint?: Maybe<Scalars['String']>;
  hint_not?: Maybe<Scalars['String']>;
  hint_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hint_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hint_contains?: Maybe<Scalars['String']>;
  hint_not_contains?: Maybe<Scalars['String']>;
  type_exists?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  order_exists?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<LegalDocumentFilter>>>;
  AND?: Maybe<Array<Maybe<LegalDocumentFilter>>>;
};

export type LegalDocumentLinkingCollections = {
  __typename?: 'LegalDocumentLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type LegalDocumentLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum LegalDocumentOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/migration) */
export type Migration = Entry & {
  __typename?: 'Migration';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<MigrationLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/migration) */
export type MigrationLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/migration) */
export type MigrationTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/migration) */
export type MigrationHashArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/migration) */
export type MigrationTimestampArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type MigrationCollection = {
  __typename?: 'MigrationCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Migration>>;
};

export type MigrationFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  hash_exists?: Maybe<Scalars['Boolean']>;
  hash?: Maybe<Scalars['String']>;
  hash_not?: Maybe<Scalars['String']>;
  hash_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hash_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hash_contains?: Maybe<Scalars['String']>;
  hash_not_contains?: Maybe<Scalars['String']>;
  timestamp_exists?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_not?: Maybe<Scalars['DateTime']>;
  timestamp_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  timestamp_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  timestamp_gt?: Maybe<Scalars['DateTime']>;
  timestamp_gte?: Maybe<Scalars['DateTime']>;
  timestamp_lt?: Maybe<Scalars['DateTime']>;
  timestamp_lte?: Maybe<Scalars['DateTime']>;
  OR?: Maybe<Array<Maybe<MigrationFilter>>>;
  AND?: Maybe<Array<Maybe<MigrationFilter>>>;
};

export type MigrationLinkingCollections = {
  __typename?: 'MigrationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type MigrationLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum MigrationOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  HashAsc = 'hash_ASC',
  HashDesc = 'hash_DESC',
  TimestampAsc = 'timestamp_ASC',
  TimestampDesc = 'timestamp_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


export type Query = {
  __typename?: 'Query';
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  faqQuestion?: Maybe<FaqQuestion>;
  faqQuestionCollection?: Maybe<FaqQuestionCollection>;
  legalDocument?: Maybe<LegalDocument>;
  legalDocumentCollection?: Maybe<LegalDocumentCollection>;
  faqCategory?: Maybe<FaqCategory>;
  faqCategoryCollection?: Maybe<FaqCategoryCollection>;
  branch?: Maybe<Branch>;
  branchCollection?: Maybe<BranchCollection>;
  article?: Maybe<Article>;
  articleCollection?: Maybe<ArticleCollection>;
  stepWithNumber?: Maybe<StepWithNumber>;
  stepWithNumberCollection?: Maybe<StepWithNumberCollection>;
  warrantyInfoContent?: Maybe<WarrantyInfoContent>;
  warrantyInfoContentCollection?: Maybe<WarrantyInfoContentCollection>;
  warrantyInfoCard?: Maybe<WarrantyInfoCard>;
  warrantyInfoCardCollection?: Maybe<WarrantyInfoCardCollection>;
  landingPage?: Maybe<LandingPage>;
  landingPageCollection?: Maybe<LandingPageCollection>;
  hiring?: Maybe<Hiring>;
  hiringCollection?: Maybe<HiringCollection>;
  branchMember?: Maybe<BranchMember>;
  branchMemberCollection?: Maybe<BranchMemberCollection>;
  userReview?: Maybe<UserReview>;
  userReviewCollection?: Maybe<UserReviewCollection>;
  exampleLocal?: Maybe<ExampleLocal>;
  exampleLocalCollection?: Maybe<ExampleLocalCollection>;
  exampleGlobal?: Maybe<ExampleGlobal>;
  exampleGlobalCollection?: Maybe<ExampleGlobalCollection>;
  example?: Maybe<Example>;
  exampleCollection?: Maybe<ExampleCollection>;
  smartContextInfo?: Maybe<SmartContextInfo>;
  smartContextInfoCollection?: Maybe<SmartContextInfoCollection>;
  roleLocation?: Maybe<RoleLocation>;
  roleLocationCollection?: Maybe<RoleLocationCollection>;
  roleSection?: Maybe<RoleSection>;
  roleSectionCollection?: Maybe<RoleSectionCollection>;
  impressum?: Maybe<Impressum>;
  impressumCollection?: Maybe<ImpressumCollection>;
  migration?: Maybe<Migration>;
  migrationCollection?: Maybe<MigrationCollection>;
  update?: Maybe<Update>;
  updateCollection?: Maybe<UpdateCollection>;
  imageGallery?: Maybe<ImageGallery>;
  imageGalleryCollection?: Maybe<ImageGalleryCollection>;
  category?: Maybe<Category>;
  categoryCollection?: Maybe<CategoryCollection>;
  entryCollection?: Maybe<EntryCollection>;
};


export type QueryAssetArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryAssetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<AssetFilter>;
  order?: Maybe<Array<Maybe<AssetOrder>>>;
};


export type QueryFaqQuestionArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryFaqQuestionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<FaqQuestionFilter>;
  order?: Maybe<Array<Maybe<FaqQuestionOrder>>>;
};


export type QueryLegalDocumentArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryLegalDocumentCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<LegalDocumentFilter>;
  order?: Maybe<Array<Maybe<LegalDocumentOrder>>>;
};


export type QueryFaqCategoryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryFaqCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<FaqCategoryFilter>;
  order?: Maybe<Array<Maybe<FaqCategoryOrder>>>;
};


export type QueryBranchArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryBranchCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<BranchFilter>;
  order?: Maybe<Array<Maybe<BranchOrder>>>;
};


export type QueryArticleArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryArticleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<ArticleFilter>;
  order?: Maybe<Array<Maybe<ArticleOrder>>>;
};


export type QueryStepWithNumberArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryStepWithNumberCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<StepWithNumberFilter>;
  order?: Maybe<Array<Maybe<StepWithNumberOrder>>>;
};


export type QueryWarrantyInfoContentArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryWarrantyInfoContentCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<WarrantyInfoContentFilter>;
  order?: Maybe<Array<Maybe<WarrantyInfoContentOrder>>>;
};


export type QueryWarrantyInfoCardArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryWarrantyInfoCardCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<WarrantyInfoCardFilter>;
  order?: Maybe<Array<Maybe<WarrantyInfoCardOrder>>>;
};


export type QueryLandingPageArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryLandingPageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<LandingPageFilter>;
  order?: Maybe<Array<Maybe<LandingPageOrder>>>;
};


export type QueryHiringArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryHiringCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<HiringFilter>;
  order?: Maybe<Array<Maybe<HiringOrder>>>;
};


export type QueryBranchMemberArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryBranchMemberCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<BranchMemberFilter>;
  order?: Maybe<Array<Maybe<BranchMemberOrder>>>;
};


export type QueryUserReviewArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryUserReviewCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<UserReviewFilter>;
  order?: Maybe<Array<Maybe<UserReviewOrder>>>;
};


export type QueryExampleLocalArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryExampleLocalCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<ExampleLocalFilter>;
  order?: Maybe<Array<Maybe<ExampleLocalOrder>>>;
};


export type QueryExampleGlobalArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryExampleGlobalCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<ExampleGlobalFilter>;
  order?: Maybe<Array<Maybe<ExampleGlobalOrder>>>;
};


export type QueryExampleArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryExampleCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<ExampleFilter>;
  order?: Maybe<Array<Maybe<ExampleOrder>>>;
};


export type QuerySmartContextInfoArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QuerySmartContextInfoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<SmartContextInfoFilter>;
  order?: Maybe<Array<Maybe<SmartContextInfoOrder>>>;
};


export type QueryRoleLocationArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryRoleLocationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<RoleLocationFilter>;
  order?: Maybe<Array<Maybe<RoleLocationOrder>>>;
};


export type QueryRoleSectionArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryRoleSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<RoleSectionFilter>;
  order?: Maybe<Array<Maybe<RoleSectionOrder>>>;
};


export type QueryImpressumArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryImpressumCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<ImpressumFilter>;
  order?: Maybe<Array<Maybe<ImpressumOrder>>>;
};


export type QueryMigrationArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryMigrationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<MigrationFilter>;
  order?: Maybe<Array<Maybe<MigrationOrder>>>;
};


export type QueryUpdateArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryUpdateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<UpdateFilter>;
  order?: Maybe<Array<Maybe<UpdateOrder>>>;
};


export type QueryImageGalleryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryImageGalleryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<ImageGalleryFilter>;
  order?: Maybe<Array<Maybe<ImageGalleryOrder>>>;
};


export type QueryCategoryArgs = {
  id: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type QueryCategoryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<CategoryFilter>;
  order?: Maybe<Array<Maybe<CategoryOrder>>>;
};


export type QueryEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  where?: Maybe<EntryFilter>;
  order?: Maybe<Array<Maybe<EntryOrder>>>;
};

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/roleLocation) */
export type RoleLocation = Entry & {
  __typename?: 'RoleLocation';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<RoleLocationLinkingCollections>;
  title?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/roleLocation) */
export type RoleLocationLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/roleLocation) */
export type RoleLocationTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type RoleLocationCollection = {
  __typename?: 'RoleLocationCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<RoleLocation>>;
};

export type RoleLocationFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<RoleLocationFilter>>>;
  AND?: Maybe<Array<Maybe<RoleLocationFilter>>>;
};

export type RoleLocationLinkingCollections = {
  __typename?: 'RoleLocationLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  hiringCollection?: Maybe<HiringCollection>;
};


export type RoleLocationLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type RoleLocationLinkingCollectionsHiringCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum RoleLocationOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/roleSection) */
export type RoleSection = Entry & {
  __typename?: 'RoleSection';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<RoleSectionLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/roleSection) */
export type RoleSectionLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/roleSection) */
export type RoleSectionTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/roleSection) */
export type RoleSectionColorArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type RoleSectionCollection = {
  __typename?: 'RoleSectionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<RoleSection>>;
};

export type RoleSectionFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  color_exists?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  color_not?: Maybe<Scalars['String']>;
  color_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  color_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  color_contains?: Maybe<Scalars['String']>;
  color_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<RoleSectionFilter>>>;
  AND?: Maybe<Array<Maybe<RoleSectionFilter>>>;
};

export type RoleSectionLinkingCollections = {
  __typename?: 'RoleSectionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  hiringCollection?: Maybe<HiringCollection>;
};


export type RoleSectionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type RoleSectionLinkingCollectionsHiringCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum RoleSectionOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfo = Entry & {
  __typename?: 'SmartContextInfo';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<SmartContextInfoLinkingCollections>;
  make?: Maybe<Scalars['String']>;
  makeId?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  webMessageHeadline?: Maybe<Scalars['String']>;
  webMessageBody?: Maybe<Scalars['String']>;
  buyingGuideMessageHeadline?: Maybe<Scalars['String']>;
  buyingGuideMessageBody?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfoLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfoMakeArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfoMakeIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfoModelArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfoModelIdArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfoWebMessageHeadlineArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfoWebMessageBodyArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfoBuyingGuideMessageHeadlineArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/smartContextInfo) */
export type SmartContextInfoBuyingGuideMessageBodyArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type SmartContextInfoCollection = {
  __typename?: 'SmartContextInfoCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<SmartContextInfo>>;
};

export type SmartContextInfoFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  make_exists?: Maybe<Scalars['Boolean']>;
  make?: Maybe<Scalars['String']>;
  make_not?: Maybe<Scalars['String']>;
  make_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  make_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  make_contains?: Maybe<Scalars['String']>;
  make_not_contains?: Maybe<Scalars['String']>;
  makeId_exists?: Maybe<Scalars['Boolean']>;
  makeId?: Maybe<Scalars['String']>;
  makeId_not?: Maybe<Scalars['String']>;
  makeId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  makeId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  makeId_contains?: Maybe<Scalars['String']>;
  makeId_not_contains?: Maybe<Scalars['String']>;
  model_exists?: Maybe<Scalars['Boolean']>;
  model?: Maybe<Scalars['String']>;
  model_not?: Maybe<Scalars['String']>;
  model_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  model_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  model_contains?: Maybe<Scalars['String']>;
  model_not_contains?: Maybe<Scalars['String']>;
  modelId_exists?: Maybe<Scalars['Boolean']>;
  modelId?: Maybe<Scalars['String']>;
  modelId_not?: Maybe<Scalars['String']>;
  modelId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  modelId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  modelId_contains?: Maybe<Scalars['String']>;
  modelId_not_contains?: Maybe<Scalars['String']>;
  webMessageHeadline_exists?: Maybe<Scalars['Boolean']>;
  webMessageHeadline?: Maybe<Scalars['String']>;
  webMessageHeadline_not?: Maybe<Scalars['String']>;
  webMessageHeadline_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  webMessageHeadline_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  webMessageHeadline_contains?: Maybe<Scalars['String']>;
  webMessageHeadline_not_contains?: Maybe<Scalars['String']>;
  webMessageBody_exists?: Maybe<Scalars['Boolean']>;
  webMessageBody?: Maybe<Scalars['String']>;
  webMessageBody_not?: Maybe<Scalars['String']>;
  webMessageBody_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  webMessageBody_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  webMessageBody_contains?: Maybe<Scalars['String']>;
  webMessageBody_not_contains?: Maybe<Scalars['String']>;
  buyingGuideMessageHeadline_exists?: Maybe<Scalars['Boolean']>;
  buyingGuideMessageHeadline?: Maybe<Scalars['String']>;
  buyingGuideMessageHeadline_not?: Maybe<Scalars['String']>;
  buyingGuideMessageHeadline_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  buyingGuideMessageHeadline_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  buyingGuideMessageHeadline_contains?: Maybe<Scalars['String']>;
  buyingGuideMessageHeadline_not_contains?: Maybe<Scalars['String']>;
  buyingGuideMessageBody_exists?: Maybe<Scalars['Boolean']>;
  buyingGuideMessageBody?: Maybe<Scalars['String']>;
  buyingGuideMessageBody_not?: Maybe<Scalars['String']>;
  buyingGuideMessageBody_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  buyingGuideMessageBody_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  buyingGuideMessageBody_contains?: Maybe<Scalars['String']>;
  buyingGuideMessageBody_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<SmartContextInfoFilter>>>;
  AND?: Maybe<Array<Maybe<SmartContextInfoFilter>>>;
};

export type SmartContextInfoLinkingCollections = {
  __typename?: 'SmartContextInfoLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type SmartContextInfoLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum SmartContextInfoOrder {
  MakeAsc = 'make_ASC',
  MakeDesc = 'make_DESC',
  MakeIdAsc = 'makeId_ASC',
  MakeIdDesc = 'makeId_DESC',
  ModelAsc = 'model_ASC',
  ModelDesc = 'model_DESC',
  ModelIdAsc = 'modelId_ASC',
  ModelIdDesc = 'modelId_DESC',
  WebMessageHeadlineAsc = 'webMessageHeadline_ASC',
  WebMessageHeadlineDesc = 'webMessageHeadline_DESC',
  BuyingGuideMessageHeadlineAsc = 'buyingGuideMessageHeadline_ASC',
  BuyingGuideMessageHeadlineDesc = 'buyingGuideMessageHeadline_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/stepWithNumber) */
export type StepWithNumber = Entry & {
  __typename?: 'StepWithNumber';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<StepWithNumberLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  content?: Maybe<StepWithNumberContent>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/stepWithNumber) */
export type StepWithNumberLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/stepWithNumber) */
export type StepWithNumberTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/stepWithNumber) */
export type StepWithNumberNumberArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/stepWithNumber) */
export type StepWithNumberCategoryArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/stepWithNumber) */
export type StepWithNumberContentArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type StepWithNumberCollection = {
  __typename?: 'StepWithNumberCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<StepWithNumber>>;
};

export type StepWithNumberContent = {
  __typename?: 'StepWithNumberContent';
  json: Scalars['JSON'];
  links: StepWithNumberContentLinks;
};

export type StepWithNumberContentAssets = {
  __typename?: 'StepWithNumberContentAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type StepWithNumberContentEntries = {
  __typename?: 'StepWithNumberContentEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type StepWithNumberContentLinks = {
  __typename?: 'StepWithNumberContentLinks';
  entries: StepWithNumberContentEntries;
  assets: StepWithNumberContentAssets;
};

export type StepWithNumberFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  number_exists?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  category_exists?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_contains?: Maybe<Scalars['String']>;
  category_not_contains?: Maybe<Scalars['String']>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<StepWithNumberFilter>>>;
  AND?: Maybe<Array<Maybe<StepWithNumberFilter>>>;
};

export type StepWithNumberLinkingCollections = {
  __typename?: 'StepWithNumberLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type StepWithNumberLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum StepWithNumberOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Sys = {
  __typename?: 'Sys';
  id: Scalars['String'];
  spaceId: Scalars['String'];
  environmentId: Scalars['String'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  publishedVersion?: Maybe<Scalars['Int']>;
};

export type SysFilter = {
  id_exists?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  id_not?: Maybe<Scalars['String']>;
  id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains?: Maybe<Scalars['String']>;
  id_not_contains?: Maybe<Scalars['String']>;
  publishedAt_exists?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_exists?: Maybe<Scalars['Boolean']>;
  firstPublishedAt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_not?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_gt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_gte?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lt?: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lte?: Maybe<Scalars['DateTime']>;
  publishedVersion_exists?: Maybe<Scalars['Boolean']>;
  publishedVersion?: Maybe<Scalars['Float']>;
  publishedVersion_not?: Maybe<Scalars['Float']>;
  publishedVersion_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_not_in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_gt?: Maybe<Scalars['Float']>;
  publishedVersion_gte?: Maybe<Scalars['Float']>;
  publishedVersion_lt?: Maybe<Scalars['Float']>;
  publishedVersion_lte?: Maybe<Scalars['Float']>;
};

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/update) */
export type Update = Entry & {
  __typename?: 'Update';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<UpdateLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  pinned?: Maybe<Scalars['Boolean']>;
  content?: Maybe<UpdateContent>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/update) */
export type UpdateLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/update) */
export type UpdateTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/update) */
export type UpdateSlugArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/update) */
export type UpdateCategoryArgs = {
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/update) */
export type UpdatePinnedArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/update) */
export type UpdateContentArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type UpdateCollection = {
  __typename?: 'UpdateCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Update>>;
};

export type UpdateContent = {
  __typename?: 'UpdateContent';
  json: Scalars['JSON'];
  links: UpdateContentLinks;
};

export type UpdateContentAssets = {
  __typename?: 'UpdateContentAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type UpdateContentEntries = {
  __typename?: 'UpdateContentEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type UpdateContentLinks = {
  __typename?: 'UpdateContentLinks';
  entries: UpdateContentEntries;
  assets: UpdateContentAssets;
};

export type UpdateFilter = {
  category?: Maybe<CfCategoryNestedFilter>;
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  category_exists?: Maybe<Scalars['Boolean']>;
  pinned_exists?: Maybe<Scalars['Boolean']>;
  pinned?: Maybe<Scalars['Boolean']>;
  pinned_not?: Maybe<Scalars['Boolean']>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<UpdateFilter>>>;
  AND?: Maybe<Array<Maybe<UpdateFilter>>>;
};

export type UpdateLinkingCollections = {
  __typename?: 'UpdateLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type UpdateLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum UpdateOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  PinnedAsc = 'pinned_ASC',
  PinnedDesc = 'pinned_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/userReview) */
export type UserReview = Entry & {
  __typename?: 'UserReview';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<UserReviewLinkingCollections>;
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  review?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  isHomepage?: Maybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/userReview) */
export type UserReviewLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/userReview) */
export type UserReviewNameArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/userReview) */
export type UserReviewRatingArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/userReview) */
export type UserReviewReviewArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/userReview) */
export type UserReviewLinkArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/userReview) */
export type UserReviewDateArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/userReview) */
export type UserReviewSourceArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/userReview) */
export type UserReviewIsHomepageArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type UserReviewCollection = {
  __typename?: 'UserReviewCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<UserReview>>;
};

export type UserReviewFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  name_exists?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  rating_exists?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<Scalars['Int']>;
  rating_not?: Maybe<Scalars['Int']>;
  rating_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rating_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rating_gt?: Maybe<Scalars['Int']>;
  rating_gte?: Maybe<Scalars['Int']>;
  rating_lt?: Maybe<Scalars['Int']>;
  rating_lte?: Maybe<Scalars['Int']>;
  review_exists?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['String']>;
  review_not?: Maybe<Scalars['String']>;
  review_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  review_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  review_contains?: Maybe<Scalars['String']>;
  review_not_contains?: Maybe<Scalars['String']>;
  link_exists?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  link_not?: Maybe<Scalars['String']>;
  link_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  link_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  link_contains?: Maybe<Scalars['String']>;
  link_not_contains?: Maybe<Scalars['String']>;
  date_exists?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['DateTime']>;
  date_not?: Maybe<Scalars['DateTime']>;
  date_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  date_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  date_gt?: Maybe<Scalars['DateTime']>;
  date_gte?: Maybe<Scalars['DateTime']>;
  date_lt?: Maybe<Scalars['DateTime']>;
  date_lte?: Maybe<Scalars['DateTime']>;
  source_exists?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_not?: Maybe<Scalars['String']>;
  source_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_contains?: Maybe<Scalars['String']>;
  source_not_contains?: Maybe<Scalars['String']>;
  isHomepage_exists?: Maybe<Scalars['Boolean']>;
  isHomepage?: Maybe<Scalars['Boolean']>;
  isHomepage_not?: Maybe<Scalars['Boolean']>;
  OR?: Maybe<Array<Maybe<UserReviewFilter>>>;
  AND?: Maybe<Array<Maybe<UserReviewFilter>>>;
};

export type UserReviewLinkingCollections = {
  __typename?: 'UserReviewLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
  branchCollection?: Maybe<BranchCollection>;
};


export type UserReviewLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};


export type UserReviewLinkingCollectionsBranchCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum UserReviewOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  IsHomepageAsc = 'isHomepage_ASC',
  IsHomepageDesc = 'isHomepage_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoCard) */
export type WarrantyInfoCard = Entry & {
  __typename?: 'WarrantyInfoCard';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<WarrantyInfoCardLinkingCollections>;
  content?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoCard) */
export type WarrantyInfoCardLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoCard) */
export type WarrantyInfoCardContentArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoCard) */
export type WarrantyInfoCardCategoryArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoCard) */
export type WarrantyInfoCardOrderArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type WarrantyInfoCardCollection = {
  __typename?: 'WarrantyInfoCardCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<WarrantyInfoCard>>;
};

export type WarrantyInfoCardFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  category_exists?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_contains?: Maybe<Scalars['String']>;
  category_not_contains?: Maybe<Scalars['String']>;
  order_exists?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  OR?: Maybe<Array<Maybe<WarrantyInfoCardFilter>>>;
  AND?: Maybe<Array<Maybe<WarrantyInfoCardFilter>>>;
};

export type WarrantyInfoCardLinkingCollections = {
  __typename?: 'WarrantyInfoCardLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type WarrantyInfoCardLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum WarrantyInfoCardOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoContent) */
export type WarrantyInfoContent = Entry & {
  __typename?: 'WarrantyInfoContent';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<WarrantyInfoContentLinkingCollections>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<WarrantyInfoContentContent>;
  category?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoContent) */
export type WarrantyInfoContentLinkedFromArgs = {
  allowedLocales?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoContent) */
export type WarrantyInfoContentTitleArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoContent) */
export type WarrantyInfoContentContentArgs = {
  locale?: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/pnv226tkz6vh/content_types/warrantyInfoContent) */
export type WarrantyInfoContentCategoryArgs = {
  locale?: Maybe<Scalars['String']>;
};

export type WarrantyInfoContentCollection = {
  __typename?: 'WarrantyInfoContentCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<WarrantyInfoContent>>;
};

export type WarrantyInfoContentContent = {
  __typename?: 'WarrantyInfoContentContent';
  json: Scalars['JSON'];
  links: WarrantyInfoContentContentLinks;
};

export type WarrantyInfoContentContentAssets = {
  __typename?: 'WarrantyInfoContentContentAssets';
  hyperlink: Array<Maybe<Asset>>;
  block: Array<Maybe<Asset>>;
};

export type WarrantyInfoContentContentEntries = {
  __typename?: 'WarrantyInfoContentContentEntries';
  inline: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  block: Array<Maybe<Entry>>;
};

export type WarrantyInfoContentContentLinks = {
  __typename?: 'WarrantyInfoContentContentLinks';
  entries: WarrantyInfoContentContentEntries;
  assets: WarrantyInfoContentContentAssets;
};

export type WarrantyInfoContentFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  category_exists?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_contains?: Maybe<Scalars['String']>;
  category_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<WarrantyInfoContentFilter>>>;
  AND?: Maybe<Array<Maybe<WarrantyInfoContentFilter>>>;
};

export type WarrantyInfoContentLinkingCollections = {
  __typename?: 'WarrantyInfoContentLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};


export type WarrantyInfoContentLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
};

export enum WarrantyInfoContentOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type CfArticleNestedFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  subtitle_exists?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_not?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_contains?: Maybe<Scalars['String']>;
  subtitle_not_contains?: Maybe<Scalars['String']>;
  slug_exists?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  category_exists?: Maybe<Scalars['Boolean']>;
  featured_exists?: Maybe<Scalars['Boolean']>;
  featured?: Maybe<Scalars['Boolean']>;
  featured_not?: Maybe<Scalars['Boolean']>;
  cover_exists?: Maybe<Scalars['Boolean']>;
  content_exists?: Maybe<Scalars['Boolean']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  relatedArticlesCollection_exists?: Maybe<Scalars['Boolean']>;
  seoDescription_exists?: Maybe<Scalars['Boolean']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescription_not?: Maybe<Scalars['String']>;
  seoDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  seoDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  seoDescription_contains?: Maybe<Scalars['String']>;
  seoDescription_not_contains?: Maybe<Scalars['String']>;
  metaTitle_exists?: Maybe<Scalars['Boolean']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaTitle_not?: Maybe<Scalars['String']>;
  metaTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  metaTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  metaTitle_contains?: Maybe<Scalars['String']>;
  metaTitle_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<CfArticleNestedFilter>>>;
  AND?: Maybe<Array<Maybe<CfArticleNestedFilter>>>;
};

export type CfCategoryNestedFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  type_exists?: Maybe<Scalars['Boolean']>;
  type_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>;
  OR?: Maybe<Array<Maybe<CfCategoryNestedFilter>>>;
  AND?: Maybe<Array<Maybe<CfCategoryNestedFilter>>>;
};

export type CfFaqCategoryNestedFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  type_exists?: Maybe<Scalars['Boolean']>;
  type_contains_all?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains_some?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_contains_none?: Maybe<Array<Maybe<Scalars['String']>>>;
  OR?: Maybe<Array<Maybe<CfFaqCategoryNestedFilter>>>;
  AND?: Maybe<Array<Maybe<CfFaqCategoryNestedFilter>>>;
};

export type CfRoleSectionNestedFilter = {
  sys?: Maybe<SysFilter>;
  contentfulMetadata?: Maybe<ContentfulMetadataFilter>;
  title_exists?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  color_exists?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  color_not?: Maybe<Scalars['String']>;
  color_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  color_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  color_contains?: Maybe<Scalars['String']>;
  color_not_contains?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<Maybe<CfRoleSectionNestedFilter>>>;
  AND?: Maybe<Array<Maybe<CfRoleSectionNestedFilter>>>;
};

export type ArticleFragment = (
  { __typename?: 'Article' }
  & Pick<Article, 'title' | 'subtitle' | 'seoDescription' | 'metaTitle' | 'slug'>
  & { sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id' | 'publishedAt' | 'firstPublishedAt' | 'publishedVersion'>
  ), cover?: Maybe<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'size' | 'url' | 'width' | 'height' | 'title'>
  )>, category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'title'>
  )> }
);

export type ArticleLinksFragment = (
  { __typename?: 'ArticleContentLinks' }
  & { assets: (
    { __typename?: 'ArticleContentAssets' }
    & { block: Array<Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'url'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>> }
  ), entries: (
    { __typename?: 'ArticleContentEntries' }
    & { block: Array<Maybe<(
      { __typename: 'Article' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'Branch' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'BranchMember' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'Category' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'Example' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'ExampleGlobal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'ExampleLocal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'FaqCategory' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'FaqQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'ImageGallery' }
      & { imagesCollection?: Maybe<(
        { __typename?: 'AssetCollection' }
        & { items: Array<Maybe<(
          { __typename?: 'Asset' }
          & Pick<Asset, 'url' | 'title'>
        )>> }
      )>, sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'Impressum' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'LandingPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'LegalDocument' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'Migration' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'RoleLocation' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'RoleSection' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'SmartContextInfo' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'StepWithNumber' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'Update' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'UserReview' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'WarrantyInfoCard' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename: 'WarrantyInfoContent' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>>, inline: Array<Maybe<(
      { __typename?: 'Article' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Branch' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'BranchMember' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Category' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Example' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleGlobal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleLocal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqCategory' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ImageGallery' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Impressum' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LandingPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LegalDocument' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Migration' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleLocation' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleSection' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SmartContextInfo' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'StepWithNumber' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Update' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'UserReview' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoCard' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoContent' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>>, hyperlink: Array<Maybe<(
      { __typename?: 'Article' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Branch' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'BranchMember' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Category' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Example' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleGlobal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleLocal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqCategory' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ImageGallery' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Impressum' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LandingPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LegalDocument' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Migration' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleLocation' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleSection' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SmartContextInfo' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'StepWithNumber' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Update' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'UserReview' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoCard' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoContent' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>> }
  ) }
);

export type ArticleDetailFragment = (
  { __typename?: 'Article' }
  & { content?: Maybe<(
    { __typename?: 'ArticleContent' }
    & Pick<ArticleContent, 'json'>
    & { links: (
      { __typename?: 'ArticleContentLinks' }
      & ArticleLinksFragment
    ) }
  )>, relatedArticlesCollection?: Maybe<(
    { __typename?: 'ArticleRelatedArticlesCollection' }
    & Pick<ArticleRelatedArticlesCollection, 'total' | 'skip' | 'limit'>
    & { items: Array<Maybe<(
      { __typename?: 'Article' }
      & ArticleFragment
    )>> }
  )> }
  & ArticleFragment
);

export type ArticlesQueryVariables = Exact<{
  where?: Maybe<ArticleFilter>;
  order?: Maybe<Array<Maybe<ArticleOrder>> | Maybe<ArticleOrder>>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type ArticlesQuery = (
  { __typename?: 'Query' }
  & { articleCollection?: Maybe<(
    { __typename?: 'ArticleCollection' }
    & Pick<ArticleCollection, 'total' | 'skip' | 'limit'>
    & { items: Array<Maybe<(
      { __typename?: 'Article' }
      & ArticleFragment
    )>> }
  )> }
);

export type ArticleDetailQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['Boolean']>;
}>;


export type ArticleDetailQuery = (
  { __typename?: 'Query' }
  & { articleCollection?: Maybe<(
    { __typename?: 'ArticleCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Article' }
      & ArticleDetailFragment
    )>> }
  )> }
);

export type UpdateFragment = (
  { __typename?: 'Update' }
  & Pick<Update, 'title' | 'slug' | 'pinned'>
  & { sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id' | 'publishedAt' | 'firstPublishedAt' | 'publishedVersion'>
  ), category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'title'>
  )> }
);

export type UpdateLinksFragment = (
  { __typename?: 'UpdateContentLinks' }
  & { assets: (
    { __typename?: 'UpdateContentAssets' }
    & { block: Array<Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'url'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>> }
  ), entries: (
    { __typename?: 'UpdateContentEntries' }
    & { block: Array<Maybe<(
      { __typename?: 'Article' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Branch' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'BranchMember' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Category' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Example' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleGlobal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleLocal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqCategory' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ImageGallery' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Impressum' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LandingPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LegalDocument' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Migration' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleLocation' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleSection' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SmartContextInfo' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'StepWithNumber' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Update' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'UserReview' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoCard' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoContent' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>>, inline: Array<Maybe<(
      { __typename?: 'Article' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Branch' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'BranchMember' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Category' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Example' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleGlobal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleLocal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqCategory' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ImageGallery' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Impressum' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LandingPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LegalDocument' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Migration' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleLocation' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleSection' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SmartContextInfo' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'StepWithNumber' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Update' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'UserReview' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoCard' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoContent' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>>, hyperlink: Array<Maybe<(
      { __typename?: 'Article' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Branch' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'BranchMember' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Category' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Example' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleGlobal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleLocal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqCategory' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ImageGallery' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Impressum' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LandingPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LegalDocument' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Migration' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleLocation' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleSection' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SmartContextInfo' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'StepWithNumber' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Update' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'UserReview' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoCard' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoContent' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>> }
  ) }
);

export type UpdateDetailFragment = (
  { __typename?: 'Update' }
  & Pick<Update, 'title' | 'slug' | 'pinned'>
  & { sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id' | 'publishedAt' | 'firstPublishedAt' | 'publishedVersion'>
  ), category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'title'>
  )>, content?: Maybe<(
    { __typename?: 'UpdateContent' }
    & Pick<UpdateContent, 'json'>
    & { links: (
      { __typename?: 'UpdateContentLinks' }
      & UpdateLinksFragment
    ) }
  )> }
);

export type UpdatesQueryVariables = Exact<{
  where?: Maybe<UpdateFilter>;
  order?: Maybe<Array<Maybe<UpdateOrder>> | Maybe<UpdateOrder>>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type UpdatesQuery = (
  { __typename?: 'Query' }
  & { updateCollection?: Maybe<(
    { __typename?: 'UpdateCollection' }
    & Pick<UpdateCollection, 'total' | 'skip' | 'limit'>
    & { items: Array<Maybe<(
      { __typename?: 'Update' }
      & UpdateFragment
    )>> }
  )> }
);

export type UpdateDetailQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type UpdateDetailQuery = (
  { __typename?: 'Query' }
  & { updateCollection?: Maybe<(
    { __typename?: 'UpdateCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Update' }
      & UpdateDetailFragment
    )>> }
  )> }
);

export type UserReviewFragment = (
  { __typename?: 'UserReview' }
  & Pick<UserReview, 'name' | 'rating' | 'review' | 'link' | 'date' | 'source' | 'isHomepage'>
  & { sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id'>
  ) }
);

export type BranchMemberFragment = (
  { __typename?: 'BranchMember' }
  & Pick<BranchMember, 'name' | 'position'>
  & { photo?: Maybe<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'size' | 'url' | 'width' | 'height' | 'title'>
  )> }
);

export type BranchFragment = (
  { __typename?: 'Branch' }
  & Pick<Branch, 'slug' | 'branchId' | 'title' | 'about' | 'calendlyLink'>
  & { imagesCollection?: Maybe<(
    { __typename?: 'AssetCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'size' | 'url' | 'width' | 'height' | 'title'>
    )>> }
  )>, membersCollection?: Maybe<(
    { __typename?: 'BranchMembersCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'BranchMember' }
      & BranchMemberFragment
    )>> }
  )>, reviewsCollection?: Maybe<(
    { __typename?: 'BranchReviewsCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'UserReview' }
      & UserReviewFragment
    )>> }
  )> }
);

export type BranchSellingFragment = (
  { __typename?: 'Branch' }
  & Pick<Branch, 'slug' | 'branchId' | 'sellingAbout'>
  & { imagesCollection?: Maybe<(
    { __typename?: 'AssetCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'size' | 'url' | 'width' | 'height' | 'title'>
    )>> }
  )> }
);

export type BranchQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['Boolean']>;
}>;


export type BranchQuery = (
  { __typename?: 'Query' }
  & { branchCollection?: Maybe<(
    { __typename?: 'BranchCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Branch' }
      & BranchFragment
    )>> }
  )> }
);

export type BranchesQueryVariables = Exact<{
  locale?: Maybe<Scalars['String']>;
}>;


export type BranchesQuery = (
  { __typename?: 'Query' }
  & { branchCollection?: Maybe<(
    { __typename?: 'BranchCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Branch' }
      & Pick<Branch, 'slug' | 'branchId' | 'calendlyLink'>
    )>> }
  )> }
);

export type BranchesSellingQueryVariables = Exact<{
  locale?: Maybe<Scalars['String']>;
}>;


export type BranchesSellingQuery = (
  { __typename?: 'Query' }
  & { branchCollection?: Maybe<(
    { __typename?: 'BranchCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Branch' }
      & BranchSellingFragment
    )>> }
  )> }
);

export type UserReviewQueryVariables = Exact<{
  where?: Maybe<UserReviewFilter>;
  locale?: Maybe<Scalars['String']>;
}>;


export type UserReviewQuery = (
  { __typename?: 'Query' }
  & { userReviewCollection?: Maybe<(
    { __typename?: 'UserReviewCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'UserReview' }
      & UserReviewFragment
    )>> }
  )> }
);

export type CategoriesQueryVariables = Exact<{
  where?: Maybe<CategoryFilter>;
  order?: Maybe<Array<Maybe<CategoryOrder>> | Maybe<CategoryOrder>>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categoryCollection?: Maybe<(
    { __typename?: 'CategoryCollection' }
    & Pick<CategoryCollection, 'total' | 'skip' | 'limit'>
    & { items: Array<Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'title' | 'type'>
    )>> }
  )> }
);

export type FaqQuestionFragment = (
  { __typename?: 'FaqQuestion' }
  & Pick<FaqQuestion, 'title' | 'order'>
  & { content?: Maybe<(
    { __typename?: 'FaqQuestionContent' }
    & Pick<FaqQuestionContent, 'json'>
  )>, sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id'>
  ) }
);

export type FaqCategoryFragment = (
  { __typename?: 'FaqCategory' }
  & Pick<FaqCategory, 'title' | 'type'>
);

export type FaqQuestionQueryVariables = Exact<{
  where?: Maybe<FaqQuestionFilter>;
  locale?: Maybe<Scalars['String']>;
}>;


export type FaqQuestionQuery = (
  { __typename?: 'Query' }
  & { faqQuestionCollection?: Maybe<(
    { __typename?: 'FaqQuestionCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'FaqQuestion' }
      & FaqQuestionFragment
    )>> }
  )> }
);

export type FaqCategoriesQueryVariables = Exact<{
  where?: Maybe<FaqCategoryFilter>;
  locale?: Maybe<Scalars['String']>;
}>;


export type FaqCategoriesQuery = (
  { __typename?: 'Query' }
  & { faqCategoryCollection?: Maybe<(
    { __typename?: 'FaqCategoryCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'FaqCategory' }
      & FaqCategoryFragment
    )>> }
  )> }
);

export type HiringFragment = (
  { __typename?: 'Hiring' }
  & Pick<Hiring, 'title' | 'slug' | 'localizationvisibility'>
  & { content?: Maybe<(
    { __typename?: 'HiringContent' }
    & Pick<HiringContent, 'json'>
    & { links: (
      { __typename?: 'HiringContentLinks' }
      & HiringLinksFragment
    ) }
  )>, locationsCollection?: Maybe<(
    { __typename?: 'HiringLocationsCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'RoleLocation' }
      & Pick<RoleLocation, 'title'>
    )>> }
  )>, role?: Maybe<(
    { __typename?: 'RoleSection' }
    & Pick<RoleSection, 'title' | 'color'>
  )>, video?: Maybe<(
    { __typename?: 'Asset' }
    & Pick<Asset, 'size' | 'url' | 'width' | 'height' | 'title'>
  )> }
);

export type HiringSimpleFragment = (
  { __typename?: 'Hiring' }
  & Pick<Hiring, 'title' | 'slug' | 'localizationvisibility'>
  & { locationsCollection?: Maybe<(
    { __typename?: 'HiringLocationsCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'RoleLocation' }
      & Pick<RoleLocation, 'title'>
    )>> }
  )>, role?: Maybe<(
    { __typename?: 'RoleSection' }
    & Pick<RoleSection, 'title' | 'color'>
  )> }
);

export type HiringLinksFragment = (
  { __typename?: 'HiringContentLinks' }
  & { assets: (
    { __typename?: 'HiringContentAssets' }
    & { block: Array<Maybe<(
      { __typename?: 'Asset' }
      & Pick<Asset, 'url'>
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>> }
  ), entries: (
    { __typename?: 'HiringContentEntries' }
    & { block: Array<Maybe<(
      { __typename?: 'Article' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Branch' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'BranchMember' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Category' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Example' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleGlobal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleLocal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqCategory' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ImageGallery' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Impressum' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LandingPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LegalDocument' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Migration' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleLocation' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleSection' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SmartContextInfo' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'StepWithNumber' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Update' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'UserReview' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoCard' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoContent' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>>, inline: Array<Maybe<(
      { __typename?: 'Article' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Branch' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'BranchMember' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Category' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Example' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleGlobal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleLocal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqCategory' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ImageGallery' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Impressum' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LandingPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LegalDocument' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Migration' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleLocation' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleSection' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SmartContextInfo' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'StepWithNumber' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Update' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'UserReview' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoCard' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoContent' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>>, hyperlink: Array<Maybe<(
      { __typename?: 'Article' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Branch' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'BranchMember' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Category' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Example' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleGlobal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ExampleLocal' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqCategory' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'FaqQuestion' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'ImageGallery' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Impressum' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LandingPage' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'LegalDocument' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Migration' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleLocation' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'RoleSection' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'SmartContextInfo' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'StepWithNumber' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'Update' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'UserReview' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoCard' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    ) | (
      { __typename?: 'WarrantyInfoContent' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
    )>> }
  ) }
);

export type HiringQueryVariables = Exact<{
  where?: Maybe<HiringFilter>;
  order?: Maybe<Array<Maybe<HiringOrder>> | Maybe<HiringOrder>>;
  locale?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type HiringQuery = (
  { __typename?: 'Query' }
  & { hiringCollection?: Maybe<(
    { __typename?: 'HiringCollection' }
    & Pick<HiringCollection, 'total'>
    & { items: Array<Maybe<(
      { __typename?: 'Hiring' }
      & HiringSimpleFragment
    )>> }
  )> }
);

export type HiringDetailQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type HiringDetailQuery = (
  { __typename?: 'Query' }
  & { hiringCollection?: Maybe<(
    { __typename?: 'HiringCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Hiring' }
      & { sys: (
        { __typename?: 'Sys' }
        & Pick<Sys, 'id'>
      ) }
      & HiringFragment
    )>> }
  )> }
);

export type HiringSlugByIdQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type HiringSlugByIdQuery = (
  { __typename?: 'Query' }
  & { hiringCollection?: Maybe<(
    { __typename?: 'HiringCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Hiring' }
      & Pick<Hiring, 'slug'>
    )>> }
  )> }
);

export type OpenPositionsSlugsQueryVariables = Exact<{
  where?: Maybe<HiringFilter>;
}>;


export type OpenPositionsSlugsQuery = (
  { __typename?: 'Query' }
  & { hiringCollection?: Maybe<(
    { __typename?: 'HiringCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'Hiring' }
      & Pick<Hiring, 'slug'>
    )>> }
  )> }
);

export type ImpressumFragment = (
  { __typename?: 'Impressum' }
  & Pick<Impressum, 'heading' | 'hotline' | 'hotlineTitle' | 'helpAndContactTitle' | 'helpAndContact' | 'subheading' | 'address' | 'fax' | 'faxTitle' | 'email'>
  & { sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id' | 'publishedAt' | 'firstPublishedAt' | 'publishedVersion'>
  ), content?: Maybe<(
    { __typename?: 'ImpressumContent' }
    & Pick<ImpressumContent, 'json'>
    & { links: (
      { __typename?: 'ImpressumContentLinks' }
      & { assets: (
        { __typename?: 'ImpressumContentAssets' }
        & { block: Array<Maybe<(
          { __typename?: 'Asset' }
          & Pick<Asset, 'url'>
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        )>> }
      ), entries: (
        { __typename?: 'ImpressumContentEntries' }
        & { block: Array<Maybe<(
          { __typename?: 'Article' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Branch' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'BranchMember' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Category' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Example' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'ExampleGlobal' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'ExampleLocal' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'FaqCategory' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'FaqQuestion' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Hiring' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'ImageGallery' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Impressum' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'LandingPage' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'LegalDocument' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Migration' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'RoleLocation' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'RoleSection' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SmartContextInfo' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'StepWithNumber' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Update' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'UserReview' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'WarrantyInfoCard' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'WarrantyInfoContent' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        )>>, inline: Array<Maybe<(
          { __typename?: 'Article' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Branch' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'BranchMember' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Category' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Example' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'ExampleGlobal' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'ExampleLocal' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'FaqCategory' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'FaqQuestion' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Hiring' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'ImageGallery' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Impressum' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'LandingPage' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'LegalDocument' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Migration' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'RoleLocation' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'RoleSection' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SmartContextInfo' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'StepWithNumber' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Update' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'UserReview' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'WarrantyInfoCard' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'WarrantyInfoContent' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        )>>, hyperlink: Array<Maybe<(
          { __typename?: 'Article' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Branch' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'BranchMember' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Category' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Example' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'ExampleGlobal' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'ExampleLocal' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'FaqCategory' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'FaqQuestion' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Hiring' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'ImageGallery' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Impressum' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'LandingPage' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'LegalDocument' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Migration' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'RoleLocation' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'RoleSection' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'SmartContextInfo' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'StepWithNumber' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'Update' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'UserReview' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'WarrantyInfoCard' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        ) | (
          { __typename?: 'WarrantyInfoContent' }
          & { sys: (
            { __typename?: 'Sys' }
            & Pick<Sys, 'id'>
          ) }
        )>> }
      ) }
    ) }
  )> }
);

export type ImpressumQueryVariables = Exact<{
  locale?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['Boolean']>;
}>;


export type ImpressumQuery = (
  { __typename?: 'Query' }
  & { impressumCollection?: Maybe<(
    { __typename?: 'ImpressumCollection' }
    & Pick<ImpressumCollection, 'total' | 'skip' | 'limit'>
    & { items: Array<Maybe<(
      { __typename?: 'Impressum' }
      & ImpressumFragment
    )>> }
  )> }
);

export type LandingPageQueryVariables = Exact<{
  where?: Maybe<LandingPageFilter>;
  limit?: Maybe<Scalars['Int']>;
  locale?: Maybe<Scalars['String']>;
}>;


export type LandingPageQuery = (
  { __typename?: 'Query' }
  & { landingPageCollection?: Maybe<(
    { __typename?: 'LandingPageCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'LandingPage' }
      & { featuredArticle?: Maybe<(
        { __typename?: 'Article' }
        & ArticleFragment
      )>, articlesCollection?: Maybe<(
        { __typename?: 'LandingPageArticlesCollection' }
        & { items: Array<Maybe<(
          { __typename?: 'Article' }
          & ArticleFragment
        )>> }
      )> }
    )>> }
  )> }
);

export type LegalDocumentFragment = (
  { __typename?: 'LegalDocument' }
  & Pick<LegalDocument, 'title' | 'content' | 'hint' | 'type' | 'order'>
  & { sys: (
    { __typename?: 'Sys' }
    & Pick<Sys, 'id' | 'publishedAt' | 'firstPublishedAt' | 'publishedVersion'>
  ) }
);

export type LegalDocumentsQueryVariables = Exact<{
  type: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['Boolean']>;
}>;


export type LegalDocumentsQuery = (
  { __typename?: 'Query' }
  & { legalDocumentCollection?: Maybe<(
    { __typename?: 'LegalDocumentCollection' }
    & Pick<LegalDocumentCollection, 'total' | 'skip' | 'limit'>
    & { items: Array<Maybe<(
      { __typename?: 'LegalDocument' }
      & LegalDocumentFragment
    )>> }
  )> }
);

export type RoleLocationFragment = (
  { __typename?: 'RoleLocation' }
  & Pick<RoleLocation, 'title'>
);

export type RoleSectionFragment = (
  { __typename?: 'RoleSection' }
  & Pick<RoleSection, 'title'>
);

export type RoleLocationsQueryVariables = Exact<{
  locale?: Maybe<Scalars['String']>;
}>;


export type RoleLocationsQuery = (
  { __typename?: 'Query' }
  & { roleLocationCollection?: Maybe<(
    { __typename?: 'RoleLocationCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'RoleLocation' }
      & RoleLocationFragment
    )>> }
  )> }
);

export type RoleSectionQueryVariables = Exact<{
  locale?: Maybe<Scalars['String']>;
}>;


export type RoleSectionQuery = (
  { __typename?: 'Query' }
  & { roleSectionCollection?: Maybe<(
    { __typename?: 'RoleSectionCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'RoleSection' }
      & RoleSectionFragment
    )>> }
  )> }
);

export type SmartContextInfoItemFragment = (
  { __typename?: 'SmartContextInfo' }
  & Pick<SmartContextInfo, 'makeId' | 'modelId' | 'webMessageHeadline' | 'webMessageBody'>
);

export type SmartContextInfoQueryVariables = Exact<{
  locale?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type SmartContextInfoQuery = (
  { __typename?: 'Query' }
  & { smartContextInfoCollection?: Maybe<(
    { __typename?: 'SmartContextInfoCollection' }
    & Pick<SmartContextInfoCollection, 'total'>
    & { items: Array<Maybe<(
      { __typename?: 'SmartContextInfo' }
      & SmartContextInfoItemFragment
    )>> }
  )> }
);

export type StepWithNumberFragment = (
  { __typename?: 'StepWithNumber' }
  & Pick<StepWithNumber, 'number' | 'title' | 'category'>
  & { content?: Maybe<(
    { __typename?: 'StepWithNumberContent' }
    & Pick<StepWithNumberContent, 'json'>
  )> }
);

export type StepsWithNumberQueryVariables = Exact<{
  where?: Maybe<StepWithNumberFilter>;
  locale?: Maybe<Scalars['String']>;
}>;


export type StepsWithNumberQuery = (
  { __typename?: 'Query' }
  & { stepWithNumberCollection?: Maybe<(
    { __typename?: 'StepWithNumberCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'StepWithNumber' }
      & StepWithNumberFragment
    )>> }
  )> }
);

export type WarrantyInfoContentFragment = (
  { __typename?: 'WarrantyInfoContent' }
  & Pick<WarrantyInfoContent, 'title'>
  & { content?: Maybe<(
    { __typename?: 'WarrantyInfoContentContent' }
    & Pick<WarrantyInfoContentContent, 'json'>
  )> }
);

export type WarrantyInfoCardFragment = (
  { __typename?: 'WarrantyInfoCard' }
  & Pick<WarrantyInfoCard, 'content' | 'order'>
);

export type WarrantyInfoCardQueryVariables = Exact<{
  where?: Maybe<WarrantyInfoCardFilter>;
  locale?: Maybe<Scalars['String']>;
}>;


export type WarrantyInfoCardQuery = (
  { __typename?: 'Query' }
  & { warrantyInfoCardCollection?: Maybe<(
    { __typename?: 'WarrantyInfoCardCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'WarrantyInfoCard' }
      & WarrantyInfoCardFragment
    )>> }
  )> }
);

export type WarrantyInfoContentQueryVariables = Exact<{
  where?: Maybe<WarrantyInfoContentFilter>;
  locale?: Maybe<Scalars['String']>;
}>;


export type WarrantyInfoContentQuery = (
  { __typename?: 'Query' }
  & { warrantyInfoContentCollection?: Maybe<(
    { __typename?: 'WarrantyInfoContentCollection' }
    & { items: Array<Maybe<(
      { __typename?: 'WarrantyInfoContent' }
      & WarrantyInfoContentFragment
    )>> }
  )> }
);
