import { QueryKey, useQueryClient } from 'react-query'
import { isNum } from 'driverama-core/utils/types'

type ResolveCurrentPageArgs = {
  pageFromUrl: number
  pageFromQuery: number
  isQueryStatic: boolean
}

export function resolveCurrentPage(args: ResolveCurrentPageArgs) {
  const { isQueryStatic, pageFromQuery, pageFromUrl } = args

  if (isNum(pageFromQuery)) {
    return pageFromQuery
  }

  if (isQueryStatic) {
    return pageFromUrl
  }

  return 1
}

type ResetQueryData = {
  pages: unknown[]
  pageParams: unknown[]
}

export function useResetQueryData(queryKey: QueryKey) {
  const queryClient = useQueryClient()

  function resetQueryData(direction: 'previous' | 'next') {
    queryClient.setQueryData<ResetQueryData>(queryKey, data => {
      if (direction === 'next') {
        const lastEl = data?.pages.pop()
        return {
          pages: lastEl ? [lastEl] : [],
          pageParams: [data?.pageParams.pop()]
        }
      }

      if (direction === 'previous') {
        const lastEl = data?.pages.shift()
        return {
          pages: lastEl ? [lastEl] : [],
          pageParams: [data?.pageParams.shift()]
        }
      }

      throw new Error('no direction specified!')
    })
  }

  return resetQueryData
}
