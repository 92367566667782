import { sleep } from 'driverama-core/utils/time'
import { GraphQLClient } from 'graphql-request'

export function generatePreviewClient(token: string) {
  return new GraphQLClient(`${process.env.NEXT_PUBLIC_CONTENTFUL_API_URL}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

// because Contentful rate-limits their Content Delivery API to 55 requests per second
// we might need to retry the operation with the delay specified in the header
// see: https://www.contentful.com/developers/docs/references/content-delivery-api/#/introduction/api-rate-limits
export async function rateLimitRetry<T>(
  callback: () => Promise<T>,
  maxAttempts = 3
): Promise<T> {
  let attempt = 0
  let lastRateError

  while (attempt < maxAttempts) {
    try {
      return callback()
    } catch (err) {
      let reset =
        Number.parseInt(
          err.headers?.get('X-Contentful-RateLimit-Reset') || '0'
        ) * 1000

      if (Number.isNaN(reset)) reset = 0

      if (err.status === 429 && reset > 0) {
        // eslint-disable-next-line no-console
        console.log(err)

        lastRateError = err

        // scatter wait to prevent launching 55 requests again
        await sleep(reset * (1 + Math.random()))
      } else {
        throw err
      }
    }

    attempt += 1
  }

  throw lastRateError ?? new Error('Failed to fetch within rate limit')
}

type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>
type GetSdkFn<T> = (client: GraphQLClient, withWrapper: SdkFunctionWrapper) => T
interface ContentfulError {
  message: string
  extensions: {
    contentful: {
      code: string
      requestId: string
      details: {
        type: string
        field: string
        linkType: string
        linkId: string
        linkingEntryId: string
      }
    }
  }
}

const baseClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_CONTENTFUL_API_URL}`,
  {
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`
    }
  }
)

const baseErrorWrapper: SdkFunctionWrapper = async action => {
  try {
    const result = await action()
    return result
  } catch (error) {
    const { response } = error
    const { errors } = response

    /*
     return data when the only error is UNRESOLVABLE LINK
     (contentful cannot link referenced item that is in the DRAFT state)
    */
    if (
      errors.filter(
        (err: ContentfulError) =>
          err.extensions.contentful.code !== 'UNRESOLVABLE_LINK'
      ).length === 0
    ) {
      return response.data
    } else {
      throw new Error(error)
    }
  }
}

export function generateClient<T>(
  getSdk: GetSdkFn<T>,
  client: GraphQLClient = baseClient,
  errorWrapper: SdkFunctionWrapper = baseErrorWrapper
): T {
  return getSdk(client, errorWrapper)
}
