import { getArticles } from 'api/contentful/articles'
import {
  ArticleFilter,
  ArticleFragment,
  ArticleOrder
} from 'api/contentful/generated/contentful'
import { ARTICLES_PAGE_SIZE } from 'constants/blog'
import { InfiniteData } from 'react-query'
import { resolveCurrentPage } from 'sections/blog/Blog.utils'
import { BlogSearchFormValues } from 'sections/blog/search/BlogSearchForm'
import { PromiseThenType } from 'driverama-core/utils/types'
import { PreviewData } from 'next'

type QueryVariables = Parameters<typeof getArticles>[0]

type ResolveArticleQueryVariablesArgs = {
  queryArgs: Partial<BlogSearchFormValues>
  currentPage: number
  isQueryStatic: boolean
}

function resolveUpdateQueryOrder(
  queryArgs: Partial<BlogSearchFormValues>
): ArticleOrder[] {
  let order: ArticleOrder[] = [ArticleOrder.SysFirstPublishedAtDesc]

  if (queryArgs.order === 'alphabet') {
    order = [ArticleOrder.TitleAsc, ArticleOrder.SysFirstPublishedAtDesc]
  }

  if (queryArgs.order === 'oldest') {
    order = [ArticleOrder.SysFirstPublishedAtAsc]
  }

  return order
}

function resolveArticleQueryFilters(
  queryArgs: Partial<BlogSearchFormValues>
): ArticleFilter {
  const { categories, search } = queryArgs

  const filter: ArticleFilter = {}

  if (categories && categories.length > 0) {
    filter['AND'] = [
      {
        OR: categories.map(category => ({ category: { title: category } }))
      }
    ]
  }

  if (search) {
    filter['OR'] = [{ content_contains: search }, { title_contains: search }]
  }

  return filter
}

export function resolveArticleQueryVariables(
  args: ResolveArticleQueryVariablesArgs
): QueryVariables {
  const { queryArgs, currentPage, isQueryStatic } = args

  const where = !isQueryStatic
    ? resolveArticleQueryFilters(queryArgs)
    : { featured_not: true }

  const order = resolveUpdateQueryOrder(queryArgs)

  return {
    limit: ARTICLES_PAGE_SIZE,
    skip: ARTICLES_PAGE_SIZE * (currentPage - 1),
    order,
    where
  }
}

export type ArticleQueryData = InfiniteData<
  PromiseThenType<ReturnType<typeof getArticles>>
>

type TransformQueryResultArgs = {
  data?: ArticleQueryData
  pageFromUrl: number
  isQueryStatic: boolean
}

export function transformQueryResult(args: TransformQueryResultArgs) {
  const { data, isQueryStatic, pageFromUrl } = args

  if (!data) {
    return {
      paginationHelper: undefined,
      articles: []
    }
  }

  const { pageParams, pages } = data
  const pageFromQuery = pageParams[pageParams.length - 1] as number
  const currentPage = resolveCurrentPage({
    isQueryStatic,
    pageFromUrl,
    pageFromQuery
  })

  const totalRecords = pages[0]?.total ?? 0
  const numberOfPages = Math.ceil(totalRecords / ARTICLES_PAGE_SIZE)
  const remainingNumberOfRecords =
    totalRecords - currentPage * ARTICLES_PAGE_SIZE

  const articles = pages.reduce<ArticleFragment[]>((acc, cur) => {
    return [...acc, ...cur.articles]
  }, [])

  return {
    paginationHelper: {
      currentPage,
      numberOfPages,
      remainingNumberOfRecords,
      totalRecords
    },
    articles
  }
}

type ArticleQueryPage = PromiseThenType<ReturnType<typeof getArticles>>

export function getNextPageParam(lastPage: ArticleQueryPage) {
  const { skip = 0, numberOfPages } = lastPage || {}
  const currentPage = Math.ceil(skip / ARTICLES_PAGE_SIZE) + 1
  return currentPage >= numberOfPages ? undefined : currentPage + 1
}

export function getPreviousPageParam(firstPage: ArticleQueryPage) {
  const { skip = 0 } = firstPage || {}
  const currentPage = Math.ceil(skip / ARTICLES_PAGE_SIZE) + 1
  return currentPage <= 1 ? undefined : currentPage - 1
}

export const supportedLanguages = [
  'en-US',
  ...(process.env.NEXT_PUBLIC_REGION === 'de' ? ['de'] : []),
  ...(process.env.NEXT_PUBLIC_REGION === 'nl' ? ['nl'] : [])
]

export const resolveContentfulLocale = (locale: string | undefined) => {
  if (!locale) {
    return 'en-US'
  }
  const currentLocale: string[] = supportedLanguages.filter(
    lang => lang === locale
  )
  return currentLocale.length ? currentLocale[0] : 'en-US'
}

interface ContentfulPreviewData {
  contentful: string
}

function isContentfulPreviewData(
  preview: PreviewData
): preview is ContentfulPreviewData {
  if (
    preview &&
    typeof (preview as ContentfulPreviewData).contentful === 'string'
  ) {
    return true
  }

  return false
}

export function resolveContentfulPreview(preview: PreviewData) {
  if (isContentfulPreviewData(preview)) {
    return preview.contentful
  }
  return undefined
}
